<template>
  <div>
    <b-jumbotron
      header="Goslar Service App"
      lead="Orte des Landkreises Goslar"
      header-level="5"
      class="p-4"
    >
      <b-button variant="primary" to="/places">{{
        $t("app.menu.places")
      }}</b-button>
      <hr class="my-4" />

      <p>
        Die App umfasst die Orte des Landkreises Goslar: Goslar, Bad Harzburg,
        Braunlage, Clausthal-Zellerfeld, Langelsheim, Liebenburg und Seesen.
      </p>

      <p>Features:</p>
      <ul>
        <li>
          <strong>News:</strong> Hier werden aktuelle Meldungen aus offiziellen
          Kanälen wie Landkreis, Stadt, Polizei und Feuerwehr angezeigt.
        </li>
        <li>
          <strong>Müllabfuhr:</strong> Nachdem du deinen Ort und deine Straße
          ausgewählt hast, werden dir die Abfuhrtermine der
          Kreiswirtschaftsbetriebe angezeigt. Optional können Push-Mitteilungen
          für die Termine aktiviert werden.
        </li>
        <li>
          <strong>Wetterwarnungen:</strong> Es werden Warnungen vom deutschen
          Wetterdienst für die gewählten Orte angezeigt.
        </li>
        <li>
          <strong>Profil:</strong> Optional kannst du dich registrieren, um
          deine Daten auf mehreren Plattformen zu nutzen.
        </li>
      </ul>

      <div class="d-flex flex-row flex-wrap">
        <div class="p-2">
          <a
            href="https://play.google.com/store/apps/details?id=de.danielgrams.goslar_service&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            ><img
              alt="Jetzt bei Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
              style="width: 150px; max-width: 100%"
          /></a>
        </div>
        <div class="p-2">
          <div style="margin: 0.3rem">
            <a
              href="https://apps.apple.com/de/app/goslar-service/id1503485350?itsct=apps_box_badge&amp;itscg=30200"
              style="
                display: inline-block;
                overflow: hidden;
                border-radius: 13px;
                width: 150px;
                height: 50px;
              "
              ><img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1585526400"
                alt="Download on the App Store"
                style="border-radius: 13px; width: 150px; height: 50px"
            /></a>
          </div>
        </div>
      </div>

      <hr class="my-4" />
      <small
        >Google Play und das Google Play-Logo sind Marken von Google LLC.</small
      >
    </b-jumbotron>
  </div>
</template>

<script>
export default {};
</script>
